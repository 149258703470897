import React, { ReactNode, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';

interface PortalProps {
  children: ReactNode;
}

const Portal : React.FC<PortalProps> = ({ children }) => {
  const portalRoot = useRef(document.createElement('div'));

  useEffect(() => {
    const portalElement = portalRoot.current;
    document.body.appendChild(portalElement);

    return () => {
      document.body.removeChild(portalElement);
    };
  }, []);

  return ReactDOM.createPortal(children, portalRoot.current);
};

export default Portal;
