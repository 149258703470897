import React from 'react';
import { QuestionType } from '../../types/userQuiz';
import { getTepicPicture } from '../../utils';
import styles from './QuestionOptions.module.scss';

const AnswerOption = ({
  option,
  optionsCount = 0,
  answerType,
  selectedOptions,
  handleChange,
}: {
  option: any,
  optionsCount: number,
  answerType: string,
  selectedOptions: string[],
  handleChange: (value: any) => void
}) => {
  const isActive = selectedOptions?.find((id) => id === option._id);
  function handleChangeOption() {
    if (answerType === 'uniqOption') {
      return handleChange([option._id]);
    }
    if (answerType === 'multiOption') {
      let selected = [...selectedOptions];
      const found = selected.find((s) => s === option._id);
      selected = found ? selected.filter((s) => s !== option._id) : [...selected, option._id];
      return handleChange(selected);
    }
    return null;
  }

  if (option?.pictures?.length > 0) {
    return (<button
      type='button'
      className={`${styles['picture-option']} ${option.text ? styles.withText : ''} ${styles[`row-${optionsCount}`]} ${!isActive && selectedOptions.length > 0 ? styles['not-active'] : ''} ${isActive ? styles.active : ''}`}
      onClick={() => handleChangeOption()}
    >
      {option.text && <p>{option.text}</p>}
      <div
        className={`${styles['option-pictures']} ${styles[`row-${option.pictures.length}`]}`}
      >
        {option.pictures.map((p: any) => <img
          key={p.file_name}
          src={getTepicPicture(p)}
          alt={p.file_name}/>)}
      </div>
    </button>
    );
  }
  if (option?.pictures?.length === 0 && option?.text) {
    return (<button
      type='button'
      className={`${styles['text-option']} ${isActive ? styles.active : ''}`}
      onClick={() => handleChangeOption()}
    >
      <p>{option.text}</p>
    </button>
    );
  }
  return null;
};

const Options = ({
  options,
  selectedOptions,
  answerType,
  handleChange,
  disabled = false,
}:{
  options: any,
  answerType: string,
  selectedOptions: string[],
  disabled: boolean,
  handleChange: (value: any) => void
}) => {
  const withPictures = options.find((opt: QuestionType) => opt.pictures.length > 0);
  return (
    <div className={`${styles.options} ${disabled ? styles.disabled : ''} ${withPictures ? styles.withPictures : ''}`}>
      {options.map((opt : any) => <AnswerOption
        key={opt._id}
        handleChange={handleChange}
        answerType={answerType}
        selectedOptions={selectedOptions}
        optionsCount={options?.length}
        option={opt}
      />)}
    </div>
  );
};

export default Options;
