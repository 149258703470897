import React, { useEffect } from 'react';
import {
  Routes, Route,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import SignIn from './pages/signin';
import ForgotPassword from './pages/forgot-password';
import ResetPassword from './pages/reset-password';
import QuizHome from './pages/quiz/home';
import Toaster from './lib/Toaster';
import Page404 from './pages/Page404';
import CurrentQuiz from './pages/quiz/currentQuiz';
import Profile from './pages/Profile';

const AppRoutes = () => {
  const { user } = useSelector((store: any) => store.authReducer);
  const languges = ['fr', 'en'];
  const { i18n } = useTranslation();

  function handleChangeLang(lang: string) {
    localStorage.setItem('lang', lang);
    i18n.changeLanguage(lang);
  }
  useEffect(() => {
    if (window.navigator.language) {
      let lang = window.navigator.language.split('-')[0];
      if (!languges.find((l) => l === lang)) {
        lang = 'fr';
      }
      handleChangeLang(lang);
    }
  }, []);

  return (
    <>
      <Routes>
        <Route path="/" element={<SignIn />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password/:token" element={<ResetPassword />} />
        {user?.role && (
          <>
           <Route
              path="/quiz/home"
              element={<QuizHome isHome={true}/>}
            />
            <Route
              path="/quiz/list"
              element={<QuizHome />}
            />
            <Route
              path="/quiz/:id"
              element={<CurrentQuiz />}
            />
            <Route
              path="/magic"
              element={<Page404 message='Page en cours développement'/>}
            />
            <Route
              path="/profile"
              element={<Profile />}
            />
          </>
        )}
        <Route path="*" element={<Page404 />} />
      </Routes>
      <Toaster />
    </>
  );
};

export default AppRoutes;
