// ACTIONS AUTH
export const AUTH_SIGNIN = 'AUTH_SIGNIN';
export const AUTH_GET_PROFILE = 'AUTH_GET_PROFILE';
export const AUTH_PUT_PROFILE = 'AUTH_PUT_PROFILE';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_LOADING = 'AUTH_LOADING';
export const AUTH_RESET_ERROR = 'AUTH_RESET_ERROR';
export const AUTH_ERROR = 'AUTH_ERROR';
export const SET_TOAST = 'SET_TOAST';

// ACTIONS USER QUIZZ
export const GET_USER_QUIZZES = 'GET_USER_QUIZZES';
export const GET_CURRENT_QUIZ = 'GET_CURRENT_QUIZ';
export const START_QUIZ = 'START_QUIZ';
export const POST_ANSWER = 'POST_ANSWER';
export const RESET_CURRENT_QUIZ = 'RESET_CURRENT_QUIZ';
export const USER_QUIZZ_ERROR = 'USER_QUIZZ_ERROR';
export const USER_QUIZZ_LOADING = 'USER_QUIZZ_LOADING';
export const SET_USER_QUIZZ_FILTERS = 'SET_USER_QUIZZ_FILTERS';
