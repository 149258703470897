import React, { useEffect } from 'react';
import { useForm, get, FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import illu from './images/illu.svg';
import { updateProfile } from '../../actions/auth';
import Nav from '../../components/QuizNav';
import { ErrorField, InputText } from '../../lib/HooksFormFields';
import { useAppDispatch } from '../../reducers';
import styles from './profile.module.scss';

const Profile = () => {
  const dispatch = useAppDispatch();
  const { user } = useSelector((d: any) => d.authReducer);
  const { t } = useTranslation();
  const {
    reset,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FieldValues>();

  useEffect(() => {
    if (user) {
      reset(user);
    }
  }, [user]);

  async function onSubmit(values: FieldValues) {
    await updateProfile(dispatch, values);
  }

  return (
    <div className={`${styles.container}`}>
      <header>
        <div className={styles.content}>
          <div className={styles.icon}>
            <p>
              {user.profile?.firstName.charAt(0)}
              {user.profile?.lastName.charAt(0)}
            </p>
          </div>
        </div>
      </header>
      <main>
        <div className={styles?.content}>
          <div className={styles.col}>
            <div className={`${styles.field} ${styles.email}`}>

            </div>
            <div className={styles.field}>
              <InputText
                label={t('profile.firstName')}
                control={control}
                name="profile.firstName"
                className='login'
                rules={{ required: t('input.required') }}
              />
            {get(errors, 'profile')?.firstName?.message
              && <ErrorField message={get(errors, 'profile')?.firstName?.message} />}
            </div>
            <div className={styles.field}>
              <InputText
                label={t('profile.lastName')}
                control={control}
                name="profile.lastName"
                className='login'
                rules={{ required: t('input.required') }}
              />
            {get(errors, 'profile')?.lastName?.message
              && <ErrorField message={get(errors, 'profile')?.lastName?.message} />}
            </div>
            <button
              type="button"
              className={styles.submit}
              onClick={handleSubmit(onSubmit)}
            >
              {t('btn.valid')}
            </button>

          </div>
          <img src={illu} alt='illu' />
        </div>
      </main>
      <Nav />
    </div>
  );
};

export default Profile;
