import React from 'react';
import { useSelector } from 'react-redux';
import { LuLogIn } from 'react-icons/lu';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import logo from '../../assets/images/logo.svg';
import home from './images/1_inactif.svg';
import home_active from './images/1_actif.svg';
import home_white from './images/1_inactif_white.svg';
import list from './images/2_inactif.svg';
import list_active from './images/2_actif.svg';
import list_white from './images/2_inactif_white.svg';
import magic from './images/3_inactif.svg';
import magic_active from './images/3_actif.svg';
import profile from './images/4_inactif.svg';
import profile_active from './images/4_actif.svg';

import styles from './nav.module.scss';
import { useAppDispatch } from '../../reducers';
import { AUTH_LOGOUT } from '../../actions/types';

type LinkType = {
  label: string,
  link: string,
  icons?: string[],
};

const Nav = () => {
  const { user } = useSelector((d: any) => d.authReducer);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  async function logout() {
    await dispatch({
      type: AUTH_LOGOUT,
    });
    return navigate('/');
  }

  const mobileLinks : LinkType[] = [
    {
      label: '/quiz/home',
      link: '/quiz/home',
      icons: [home, home_active],
    },
    {
      label: '/quiz',
      link: '/quiz/list',
      icons: [list, list_active],
    },
    {
      label: '/magic',
      link: '/magic',
      icons: [magic, magic_active],
    },
    {
      label: '/profile',
      link: '/profile',
      icons: [profile, profile_active],
    },
  ];
  const location = useLocation();
  const path = location.pathname + location.search;
  return (
    <div className={styles.container}>
      <div className={styles.mobile}>
        {mobileLinks.map((l) => <NavLink
          key={l.label}
          to={l.link}
          className={path === l.link ? styles.active : ''}
        >
        <div className={styles.link}>
          <div className={styles.icon}>
            {l.icons
              && <>
                <img src={l.icons[0]} className={styles.img}/>
                <img src={l.icons[1]} className={styles['active-img']}/>
              </>
            }
          </div>
        </div>
        </NavLink>)}
      </div>
      <div className={styles.desktop}>
        <div className={styles.logo}>
          <img src={logo} alt="tepik" />
        </div>
        {user.role === 'Admin'
        && <div className={styles.links}>
            <NavLink
              to={'/quiz/home'}
              className={path.includes('/home') ? styles.active : ''}
            >
            <img src={path.includes('/home') ? home_active : home_white} alt={t('quiz.nav.home')} />  <p>{t('quiz.nav.home')}</p>
          </NavLink>
          <NavLink
            to={'/quiz/list'}
            className={path.includes('/list') ? styles.active : ''}
          >
            <img src={path.includes('/list') ? list_active : list_white} alt={t('quiz.nav.list')} />  <p>{t('quiz.nav.list')}</p>
          </NavLink>
        </div>
        }
        <div className={styles.user}>
          <NavLink to='/profile'>
            <div className={styles.icon}>
              <p>
                {user.profile.firstName[0]}
                {user.profile.lastName[0]}
              </p>
            </div>
            <p>{user.profile.firstName} {user.profile.lastName}</p>
          </NavLink>
          <button
            type="button"
            onClick={() => logout()}
          >
            <LuLogIn />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Nav;
