import React, { useEffect, useState } from 'react';
import { QuestionOption } from '../../types/userQuiz';
import { hexToRGBA } from '../../utils';
import points from './images/points.svg';
import styles from './SelectsOptions.module.scss';

const colors = ['#D7549B', '#FF845E', '#4EABBF', '#27AC7D'];

const Select = ({
  option,
  answerType,
  selectedOptions,
  index,
  handleChange,
  activeGroup,
  setActiveGroup,
}: {
  option: any,
  answerType: string,
  selectedOptions: string[],
  index: number,
  activeGroup?: string | null,
  setActiveGroup: (group?: any) => void
  handleChange: (value: any) => void
}) => {
  const active = option.options?.find(
    (opt: QuestionOption) => selectedOptions.find((id :string) => opt._id === id),
  );
  function handleChangeOption(id: string, group: string) {
    if (answerType === 'uniqOption') {
      setActiveGroup();
      return handleChange([option._id]);
    }
    if (answerType === 'multiOption') {
      const selected = selectedOptions.filter(
        (s: string) => {
          const sameGroup = option.options.find((opt :QuestionOption) => opt._id === s);
          if (sameGroup?.group === group) {
            return null;
          }
          if (s === id) {
            return null;
          }
          return s;
        },
      );
      setActiveGroup();
      return handleChange([...selected, id]);
    }
    return null;
  }

  if (activeGroup && activeGroup !== option.group) {
    return null;
  }

  return (
    <div className={styles.select}>
      <button className={styles.label}
        style={{ backgroundColor: colors[index] }}
        onClick={() => setActiveGroup(activeGroup === option.group ? null : option.group)}
      >
        <p>{option.label}</p>
        {active?.text && <p>{active?.text}</p>}
        {!active?.text && <img src={points} alt="points" />}
      </button>
      {activeGroup === option.group
        && <div className={styles.items}>
          {option.options.map((opt: QuestionOption) => <button
            key={opt._id}
            className={styles.label}
            onClick={() => handleChangeOption(opt._id, option.group)}
            style={{
              border: `1px solid ${colors[index]}`,
              backgroundColor: active?._id === opt._id
                ? hexToRGBA(colors[index], 0.3) : 'transparent',

            }}
          >
            <p>{opt.text}</p>
          </button>)}
        </div>
      }
    </div>
  );
};

const SelectOptions = ({
  options,
  selectedOptions,
  answerType,
  handleChange,
  disabled = false,
}:{
  options: QuestionOption[],
  answerType: string,
  selectedOptions: string[],
  disabled: boolean,
  handleChange: (value: any) => void
}) => {
  const [selects, setSelects] = useState<any[]>([]);
  const [activeGroup, setActiveGroup] = useState<string | null>();

  useEffect(() => {
    if (options) {
      const selectList : any = [];
      options.forEach((opt :QuestionOption) => {
        if (!selectList.find((o :any) => o.group === opt.group)) {
          selectList.push({
            group: opt.group,
            label: opt.groupLabel,
            options: options.filter((o: QuestionOption) => o.group === opt.group),
          });
        }
      });
      setSelects(selectList);
    }
  }, [options]);

  return (
    <div className={`${styles.options} ${disabled ? styles.disabled : ''}`}>
      {selects.map((opt : QuestionOption, i: number) => <Select
        key={opt.group}
        index={i}
        handleChange={handleChange}
        activeGroup={activeGroup}
        setActiveGroup={setActiveGroup}
        answerType={answerType}
        selectedOptions={selectedOptions}
        option={opt}
      />)}
    </div>
  );
};

export default SelectOptions;
