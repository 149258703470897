import React, { useEffect } from 'react';
import { useForm, SubmitHandler, FieldValues } from 'react-hook-form';
import { NavLink } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { resetError } from '../../../../actions/auth';

import {
  InputEmail,
  ErrorField,
} from '../../../../lib/HooksFormFields';

import styles from './emailresetpwd-form.module.scss';

interface ResetPasswordFormProps {
  errorMessage: string,
  succeedMessage?: string,
  signinLink: string,
  submit: SubmitHandler<FieldValues>,
}

const ResetPasswordForm: React.FC<ResetPasswordFormProps> = ({
  errorMessage,
  succeedMessage,
  signinLink,
  submit,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  });

  const watchEmail = watch('email');

  useEffect(() => resetError(dispatch), []);

  useEffect(() => {
    if (errorMessage) resetError(dispatch);
  }, [watchEmail]);

  function onSubmit(data: FieldValues) {
    submit(data);
  }

  function handlePressEnter(e: any) {
    if (e.key === 'Enter') {
      handleSubmit(onSubmit)();
    }
  }

  return (
    <div className={styles['emailresetpwd-form']}>
      <div className={styles.form} onKeyDown={handlePressEnter}>
        <div className={styles['container-field']}>
          <InputEmail
            name="email"
            control={control}
            required
            className='login'
            label={t('label.email')}
          />
          {(typeof errors.email?.message === 'string') && <ErrorField message={errors.email.message} />}
        </div>
        {(!errors.email) && errorMessage
          && (
          <p className={styles['message-error']}>
            {errorMessage}
          </p>
          )}
        {succeedMessage && (
          <p className={styles['message-succeed']}>{succeedMessage}</p>
        )}
      </div>
      <div className={styles.containerSubmit}>
        <button onClick={handleSubmit(onSubmit)}>{t('btn.valid')}</button>
        {signinLink
        && (
        <div className={styles['container-link']}>
          <NavLink to={signinLink}>{t('forgot.login')}</NavLink>
        </div>
        )}
      </div>
    </div>
  );
};

export default ResetPasswordForm;
