import React, { useEffect } from 'react';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { NavLink } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { resetError } from '../../../../actions/auth';

import {
  InputPassword,
  ErrorField,
} from '../../../../lib/HooksFormFields';

import styles from './resetpwd-form.module.scss';

interface ResetPasswordProps {
  errorMessage: string,
  signinLink?: string | null,
  succeedMessage?: string | null,
  submit: SubmitHandler<FieldValues>,
}

const ResetPwdForm: React.FC<ResetPasswordProps> = ({
  errorMessage,
  signinLink,
  succeedMessage,
  submit,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
  });
  const watchPassword = watch('password');
  const watchPasswordConfirm = watch('password-confirm');

  useEffect(() => resetError(dispatch), []);

  useEffect(() => {
    if (errorMessage) resetError(dispatch);
  }, [watchPassword, watchPasswordConfirm]);

  function onSubmit(data: FieldValues) {
    submit(data);
  }

  function handlePressEnter(e: any) {
    if (e.key === 'Enter') {
      handleSubmit(onSubmit)();
    }
  }

  return (
    <div className={styles['resetpwd-form']}>
      <div className={styles.form} onKeyDown={handlePressEnter}>
        <div className={styles['container-field']}>
          <InputPassword
            name="password"
            control={control}
            required
            className='login'
            label={t('reset.new')}
          />
          {(typeof errors.password?.message === 'string') && <ErrorField message={errors.password.message} />}
        </div>
        <div className={styles['container-field']}>
          <InputPassword
            name="confirm-password"
            control={control}
            required
            className='login'
            label={t('reset.confirm')}
          />
          {(typeof errors['confirm-password']?.message === 'string') && <ErrorField message={errors['confirm-password'].message} />}
        </div>
        {(signinLink && succeedMessage) ? (
          <div className={styles.succeed}>
            <NavLink to={signinLink}>
              <button
                className={`secondary ${styles.btnSucceed}`}
              >
                {t('reset.login')}
              </button>
            </NavLink>
            <p className={styles['message-succeed']}>{succeedMessage}</p>
          </div>
        ) : (
          <div className={styles.submit}>
            <button onClick={handleSubmit(onSubmit)}>{t('btn.valid')}</button>
            {(!errors.email && !errors.password) && errorMessage
              && (
              <p className={styles['message-error']}>
                {errorMessage}
              </p>
              )}
          </div>
        )}
      </div>

    </div>
  );
};

export default ResetPwdForm;
