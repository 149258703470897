import React, { useEffect, useMemo } from 'react';
import { useForm, FieldValues } from 'react-hook-form';
import { HiChevronRight, HiChevronLeft } from 'react-icons/hi2';
import { FaCheck } from 'react-icons/fa6';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { postAnswerAction } from '../../actions/userQuizz';
import { useAppDispatch } from '../../reducers';
import {
  Answer, PictureType, QuestionType,
} from '../../types/userQuiz';
import { getTepicPicture } from '../../utils';
import Portal from '../Portal';
import styles from './Question.module.scss';
import Options from '../QuestionOptions';
import PairsOptions from '../PairsOptions';
import SelectsOptions from '../SelectsOptions';
import InputsOptions from '../InputsOptions';

const Pictures = ({ pictures }: { pictures: PictureType[] }) => (
    <div className={`${styles.pictures} ${pictures.length > 0 ? styles[`col-${pictures.length}`] : styles['col-1']}`}>
      {pictures.length > 0 && pictures?.map((p: PictureType) => <div className={styles.img}
          key={p.file_name}
        >
          <img src={getTepicPicture(p)} alt={p.file_name} />
         </div>)}
    </div>
);

export default function Question({
  question,
  index,
  handleQuestionIndex,
}:{
  question : QuestionType,
  index: number,
  handleQuestionIndex: (i: number) => void,
}) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { current } = useSelector((s: any) => s.userQuizReducer);
  const quiz = current?.quiz && { ...current.quiz };
  const answers = current?.answers && { ...current.answers };
  const prevAnswer = answers?.entries?.length > 0 && [...answers.entries].find(
    (e: Answer) => e.question === question?._id,
  );
  const isSelect = question?.type === 'QRU_C_SELECTS';

  const {
    handleSubmit,
    setValue,
    reset,
    watch,
    control,
  } = useForm({
    defaultValues: {
      selectedOptions: [],
      selectedPairs: [],
      inputs: {

      },
    },
  });

  const selectedOptions = watch('selectedOptions');
  const selectedPairs = watch('selectedPairs');
  const form : any = watch();

  const submitDisabled = useMemo(() => {
    if (question.inputs?.length > 0) {
      if (question.inputs.filter(
        (d) => (form.inputs[d.property] ? d : null),
      ).length === question.inputs.length) {
        return false;
      }
      return true;
    }
    console.log(isSelect);
    if (isSelect) {
      const selectGroup = question.options.map((item) => item.group)
        .filter((value, i, self) => self.indexOf(value) === i);
      console.log(selectGroup);
      if (selectedOptions.length === selectGroup.length) {
        return false;
      }
      return true;
    }
    if ((question.pairs.length > 0
      && selectedPairs.length === question.pairs.length / 2) || prevAnswer) {
      return false;
    }
    if ((selectedOptions.length === 0) || prevAnswer) {
      return true;
    }
    return false;
  }, [
    selectedOptions,
    question.pairs,
    selectedPairs,
    isSelect,
    question.type,
    question.inputs,
    form,
    question.answerType,
  ]);

  async function onSubmit(values: FieldValues) {
    const payload = {
      question: question._id,
      ...values,
    };
    const res = await postAnswerAction(dispatch, answers?._id, payload);
    if (res) {
      await handleQuestionIndex(index + 1);
    }
  }

  console.log(submitDisabled);

  useEffect(() => {
    if (prevAnswer) {
      reset(prevAnswer);
    }
  }, [prevAnswer]);

  return (
    <div className={styles.container}>
      {question?.pictures?.length > 0 && <Pictures pictures={question?.pictures} />}
      <div className={`
        ${styles.question}
        ${question?.pictures?.length === 0 ? '' : styles.withPictures}`}>
        <p
          className={`${styles.sentence}`}
          dangerouslySetInnerHTML={{ __html: question.text }}
        />
        {question.inputs.length > 0 && <InputsOptions
            inputs={question.inputs}
            answerType={question.answerType}
            control={control}
            disabled={prevAnswer}
          />
        }
        {isSelect && question.options.length > 0 && <SelectsOptions
            options={question.options}
            selectedOptions={selectedOptions}
            disabled={prevAnswer}
            answerType={question.answerType}
            handleChange={(value) => setValue('selectedOptions', value)}
          />
        }
        {!isSelect && question.options.length > 0 && <Options
            options={question.options}
            selectedOptions={selectedOptions}
            disabled={prevAnswer}
            answerType={question.answerType}
            handleChange={(value) => setValue('selectedOptions', value)}
          />
        }
        {question.pairs.length > 0 && <PairsOptions
            pairs={[...question.pairs]}
            selectedPairs={selectedPairs}
            disabled={prevAnswer}
            questionType={question.type}
            answerType={question.answerType}
            handleChange={(value) => setValue('selectedPairs', value)}
          />
        }
      </div>
      <Portal>
        <div className={`${styles.actions} ${submitDisabled ? styles.disabled : ''} ${prevAnswer ? styles.submited : ''}`}>
          <div className={styles.nav}>
            {index > 0 && quiz.allowPrevious
            && <button
              type="button"
              onClick={() => handleQuestionIndex(index - 1)}
            >
              <HiChevronLeft />
            </button>
          }
          </div>
          <button
            type="button"
            className={`${styles.submit}`}
            onClick={handleSubmit(onSubmit)}
          >
            {!prevAnswer && <FaCheck size={18} />}{prevAnswer ? t('btn.submited') : t('btn.valid')}
          </button>
          <div className={styles.nav}>
            {prevAnswer && quiz.allowPrevious
              && <button
                type="button"
                onClick={() => handleQuestionIndex(index + 1)}
              >
                <HiChevronRight />
              </button>
            }
          </div>
        </div>
      </Portal>
    </div>
  );
}
