import React, { useEffect, useState } from 'react';
import { MdClose } from 'react-icons/md';
import { useSelector } from 'react-redux';
import searchIcon from './search.svg';
import searchIconActive from './search_active.svg';
import styles from './search.module.scss';

export default function Search({
  handleChangeFilters,
  placeholder,
}:{
  handleChangeFilters: (obj: any) => void,
  placeholder?: string,
}) {
  const { filters } = useSelector((s: any) => s.userQuizReducer);
  const [inputSearch, setInputSearch] = useState<string>(filters.search || '');
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newText = event.target.value;
    setInputSearch(newText);
    setIsSaving(true);
  };

  const clearSearch = () => {
    setInputSearch('');
    if (filters.search) {
      handleChangeFilters({
        search: '',
      });
    }
  };

  useEffect(() => {
    const saveTimeout = setTimeout(() => {
      if (isSaving) {
        // Perform your save action here (e.g., an API call)
        handleChangeFilters({
          page: 0,
          search: inputSearch,
        });
        setIsSaving(false);
      }
    }, 1000);

    return () => clearTimeout(saveTimeout);
  }, [inputSearch, isSaving]);

  return (
    <div className={styles.container}>
      <div className={`${styles.input}`}>
        <div className={styles.icon}>
          <img src={inputSearch ? searchIconActive : searchIcon} alt={placeholder} />
        </div>
        <input
          value={inputSearch}
          placeholder={ placeholder || 'Rechercher'}
          onChange={(e) => handleChange(e)}
        />
        {filters.search
        && <button
          className={styles.reset}
          onClick={() => clearSearch()}
        >
          <MdClose />
        </button>
        }
      </div>
    </div>
  );
}
