import { useWindowSize } from '@uidotdev/usehooks';
import React, { useEffect, useState } from 'react';
import { GoArrowLeft } from 'react-icons/go';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { getCurrentQuizzAction, startQuizzAction } from '../../../actions/userQuizz';
import Loader from '../../../components/Loader';
import Question from '../../../components/Question';
import Timer from '../../../components/Timer';
import endImg from './images/end.svg';
import endMobileImg from './images/endMobile.svg';
import { useAppDispatch } from '../../../reducers';
import { Answer, QuestionType } from '../../../types/userQuiz';
import styles from './currentQuiz.module.scss';

const CurrentQuiz = () => {
  const params = useParams();
  const quizzId = params?.id;
  const dispatch = useAppDispatch();
  const { current } = useSelector((s: any) => s.userQuizReducer);
  const [currentIndex, setCurrentIndex] = useState(0);
  const { width } = useWindowSize();
  const { quiz } = current;
  const awnsers = current?.answers && current.answers;
  const questions = quiz?.questions?.length > 0 ? [...quiz.questions] : [];
  const progress = questions?.length && ((currentIndex) / questions.length) * 100;
  const correctsAwnsers = awnsers?.entries?.filter((e: Answer) => e.isCorrect)?.length || 0;

  function handleQuestionIndex(index: number) {
    let newIndex = index;
    if (index < 0) {
      newIndex = 0;
    }
    if (index === questions.length + 1) {
      newIndex = questions.length;
    }
    setCurrentIndex(newIndex);
  }

  const getStyle = () => {
    if (width && questions?.length) {
      const style = {
        width: `${(questions.length + 1) * width}px`,
        left: `-${currentIndex * width}px`,
      };
      return style;
    }
    return {};
  };

  const getPrevClass = (i :number) => {
    if (currentIndex + 1 === i) {
      return styles.next;
    }
    if (currentIndex + -1 === i) {
      return styles.prev;
    }
    return '';
  };

  useEffect(() => {
    if (quizzId) {
      getCurrentQuizzAction(dispatch, quizzId);
      startQuizzAction(dispatch, quizzId);
    }
  }, [quizzId]);

  return (
    <div className={styles.container}>
      <Link to={'/quiz/home'} className={styles.back}>
        <GoArrowLeft />
      </Link>
      <header>
        <div className={styles.infos}>
          <div className={styles.name}>
            <h2>{quiz?.name}</h2>
          </div>
          <div className={styles.count}>
            <p>Q {currentIndex < questions.length
              ? currentIndex + 1 : questions.length}  / {questions?.length}</p>
            {quiz?.duration && <Timer duration={quiz?.duration}/>}
          </div>
        </div>
        {progress > 0
            && <div className={styles.progress}>
              <div className={styles.bar}
                style={{ width: `${progress}%` }}
              />
            </div>
          }
      </header>
      {currentIndex === questions.length && <div className={styles.end}>
          <img src={endImg} className={styles.desktop}/>
          <img src={endMobileImg} className={styles.mobile}/>
          <h1>Bravo !</h1>
          <p>Tu as réussi à finir le test</p>

          <p className={styles.score}>
            Tu as obtenu<br/>{' '}un score de {correctsAwnsers} / {questions?.length}
          </p>
        </div>
      }
      {awnsers && questions && width && currentIndex < questions.length && <div
        className={styles.content}
      >
        <div
          className={styles.list}
          style={getStyle()}>
          {questions.map((q : QuestionType, index: number) => (<div
            key={q?._id}
            className={`${styles.question} ${getPrevClass(index)}`}
          >
              {index === currentIndex ? <Question
                question={q}
                index={index}
                handleQuestionIndex={handleQuestionIndex}
              />
                : <div className={styles['empty-question']}/>}
          </div>))}
        </div>
      </div>
      }
      {!awnsers && !questions && width && <div className={styles.loader}>
          <Loader isWhite={!!(width && width > 900)}/>
        </div>
      }
    </div>
  );
};

export default CurrentQuiz;
