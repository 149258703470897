/* eslint-disable @typescript-eslint/no-shadow */
import React from 'react';
import {
  DragDropContext, Droppable, Draggable, DropResult,
} from 'react-beautiful-dnd';
import { PairOption } from '../../types/userQuiz';
import { getTepicPicture } from '../../utils';
import styles from './PairsOptions.module.scss';

const PairItem = ({
  item,
  isDragging = false,
  response,
}:{
  item : PairOption,
  isDragging?: boolean,
  response?: PairOption
}) => {
  if (response || isDragging) {
    return (<div className={`${styles.option} ${styles.empty} ${item?.pictures?.length === 0 && item?.text ? styles.text : ''} ${item?.pictures?.length > 0 ? styles.pictures : ''}`}>
    </div>);
  }
  return (
    <div className={`${styles.option} ${item?.pictures?.length === 0 && item?.text ? styles.text : ''}`}>
      {item?.text && <p>{item.text}</p>}
      {item.pictures?.length > 0
         && <div
          className={`${styles['option-pictures']} ${styles[`row-${item.pictures.length}`]}`}
       >
         {item.pictures.map((p: any) => <img
           key={p.file_name}
           src={getTepicPicture(p)}
           alt={p.file_name}/>)}
       </div>
      }
    </div>
  );
};

const ResponseItem = ({
  item,
  response,
  isDraggingOver = false,
  questionType,
  removeResponse,
}:{
  item : PairOption,
  response?: PairOption,
  questionType?: string,
  isDraggingOver?: boolean,
  removeResponse: () => void
}) => (
  <div className={`${styles.option} ${styles.response} ${questionType ? styles[questionType] : ''}  ${isDraggingOver ? styles.hover : ''}`}>
    {item?.text && <p>{item.text}</p>}
    <div className={`${styles.input} ${questionType === 'QRA_R_PICTURES' ? styles.match : ''}  ${!response?.text && response?.pictures ? styles['pictures-active'] : ''} ${response?.text ? styles.active : ''} ${isDraggingOver ? styles.hover : ''}`}
      onClick={() => removeResponse()}
    >
      {response?.text && <p>{response?.text}</p>}
      {response?.pictures && !response?.text
         && <div
          className={`${styles['option-pictures']} ${questionType === 'QRA_R_PICTURES' ? styles.matchPictures : ''} ${styles[`row-${response.pictures.length}`]}`}
       >
         {response.pictures.map((p: any) => <img
           key={p.file_name}
           src={getTepicPicture(p)}
           alt={p.file_name}/>)}
       </div>
      }
      {questionType === 'QRA_R_PICTURES' && response?.pictures && <div
          className={`${styles['option-pictures']} ${styles.matchPictures} ${styles[`row-${item.pictures.length}`]}`}
       >
         {item.pictures.map((p: any) => <img
           key={p.file_name}
           src={getTepicPicture(p)}
           alt={p.file_name}/>)}
       </div>
      }
      {item.pictures && !response?.pictures
         && <div
          className={`${styles['option-pictures']} ${styles[`row-${item.pictures.length}`]}`}
       >
         {item.pictures.map((p: any) => <img
           key={p.file_name}
           src={getTepicPicture(p)}
           alt={p.file_name}/>)}
       </div>
      }
    </div>
  </div>
);

const PairsOptions = ({
  pairs,
  selectedPairs,
  handleChange,
  disabled = false,
  questionType,
}:{
  pairs: any,
  answerType?: string,
  questionType?: string,
  selectedPairs: string[],
  disabled?: boolean,
  handleChange: (value: any) => void
}) => {
  const listA: PairOption[] = [...pairs].filter((p: PairOption) => p.group === '1');
  const listB: PairOption[] = [...pairs].filter((p: PairOption) => p.group === '2');
  console.log(questionType);
  const getItemStyle = (isDragging: boolean, draggableStyle: any) => {
    // some basic styles to make the items look a bit nicer
    const style : any = {
      ...draggableStyle,
      transition: null,
      transform: isDragging ? draggableStyle.transform : null,
    };
    return style;
  };

  const handleDragEnd = (result: DropResult) => {
    const { source, destination } = result;
    if (!destination) {
      return;
    }
    const elementB = listB.find((b) => b._id === destination.droppableId);
    if (source.droppableId === 'listA' && elementB?._id) {
      const elementA = listA[source.index];
      const selected = [...selectedPairs.filter((s) => !s.includes(elementB._id))];
      handleChange([...selected, `${elementB._id}.${elementA._id}`]);
    }
  };
  console.log(questionType);
  return (
    <div className={`${styles.container} ${disabled ? styles.disabled : ''}`}>
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="listA" isDropDisabled={true}>
        {(droppableProvided) => (
          <div
            ref={droppableProvided.innerRef}
            className={styles.list}
          >
            {listA.map((item, index) => (
              <Draggable
                key={item._id}
                draggableId={item._id}
                index={index}
                isDragDisabled={!!listB.find(
                  (r) => selectedPairs.find((s) => s.includes(`${r._id}.${item._id}`)),
                )}>
                {(provided, snapshot) => (
                  <>
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style,
                      )}
                    >
                      <PairItem
                      item={item}
                      response={listB.find(
                        (r) => selectedPairs.find((s) => s.includes(`${r._id}.${item._id}`)),
                      )} />
                    </div>
                    {snapshot.isDragging && (
                      <div>
                        <PairItem
                          item={item}
                          isDragging={snapshot.isDragging}
                          response={listB.find(
                            (r) => selectedPairs.find((s) => s.includes(`${r._id}.${item._id}`)),
                          )} />
                      </div>
                    )}

                  </>
                )}
              </Draggable>
            ))}
            {droppableProvided.placeholder && null}

          </div>
        )}
      </Droppable>
      <div
        className={styles.list}
        >
        {listB.map((item) => (
          <Droppable key={item._id} droppableId={item._id}
          >
            {(provided, snapshot) => (
              <>
              <div
                ref={provided.innerRef}
                style={{
                  transform: '',
                }}
              >
                <ResponseItem
                  item={item}
                  isDraggingOver={snapshot.isDraggingOver}
                  questionType={questionType}
                  response={listA.find(
                    (r) => selectedPairs.find((s) => s.includes(`${item._id}.${r._id}`)),
                  )}
                  removeResponse={() => handleChange([...selectedPairs.filter(
                    (s) => !s.includes(item._id),
                  )])}
                />
              </div>
              {provided.placeholder && null}
            </>
            )}
          </ Droppable>
        ))}
      </div>
    </DragDropContext>
    </div>
  );
};

export default PairsOptions;
