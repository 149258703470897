import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { GET_USER_QUIZZES, RESET_CURRENT_QUIZ, SET_USER_QUIZZ_FILTERS } from '../../../actions/types';
import { getUserQuizzListAction } from '../../../actions/userQuizz';
import Loader from '../../../components/Loader';
import QuizModal from '../../../components/QuizModal';
import Nav from '../../../components/QuizNav';
import QuizzList from '../../../components/QuizzList';
import Search from '../../../components/Search';
import { useAppDispatch } from '../../../reducers';
import styles from './home.module.scss';

const Home = ({ isHome = false } : { isHome?: Boolean }) => {
  const dispatch = useAppDispatch();
  const { user } = useSelector((d: any) => d.authReducer);
  const { filters, quizzList, isLoading } = useSelector((s: any) => s.userQuizReducer);
  const pageLoading = isLoading.find((l: string) => l === GET_USER_QUIZZES);
  const { t } = useTranslation();

  function handleChangeFilters(obj: any) {
    dispatch({
      type: SET_USER_QUIZZ_FILTERS,
      payload: obj,
    });
  }
  useEffect(() => {
    dispatch({
      type: RESET_CURRENT_QUIZ,
    });
  }, []);

  useEffect(() => {
    getUserQuizzListAction(dispatch, filters);
  }, [filters.search]);
  return (
    <div className={`${styles.container} ${isHome ? styles.home : ''}`}>
      <header>
        <div className={styles.content}>
        {isHome
          ? <>
            <p>{user?.profile?.firstName && t('quiz.home.welcome', { name: user?.profile?.firstName })}</p>
            <h2>{t('quiz.home.start1')}{' '}<br/>{t('quiz.home.start2')}</h2>
          </>
          : <h2>{t('quiz.home.title')}</h2>
        }
        <Search
          handleChangeFilters={handleChangeFilters}
          placeholder={t('quiz.search')}
        />
        </div>
      </header>
      <main>
        {pageLoading ? <div className={styles.loader}>
          <Loader />
        </div>
          : <>
          {quizzList?.length > 0 && <QuizzList
             list={quizzList}
             label={isHome ? 'Quiz disponibles' : ''}

          />}
        </>
        }
      </main>
      <QuizModal />
      <Nav />
    </div>
  );
};

export default Home;
