import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { getProfileAction } from '../../actions/auth';
import styles from './page404.module.scss';

const Page404 = ({ message }: { message?: string }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    getProfileAction(dispatch).catch(() => {
      navigate('/');
    });
  }, []);

  return (
    <div
      className={styles.container}
    >
      <p>{message || t('404')}</p>
      <Link to="/quiz/home">
        {t('quiz.nav.home')}
      </Link>
    </div>
  );
};

export default Page404;
