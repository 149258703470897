import React from 'react';
import { InputOption } from '../../types/userQuiz';
import InputText from '../../lib/HooksFormFields/InputText';
import styles from './InputsOptions.module.scss';

const InputsOptions = ({
  inputs,
  control,
  disabled = false,
}:{
  inputs: InputOption[],
  answerType: string,
  control: any,
  disabled: boolean,
}) => (
    <div className={`${styles.options} ${disabled ? styles.disabled : ''}`}>
      {inputs.map((input : InputOption) => <div
        key={input?._id}
        className={styles.field}
        >
          <InputText
          name={`inputs.${input.property}`}
          label={input.label}
          control={control}
          className={'login'}
        />
      </div>)}
    </div>
);

export default InputsOptions;
