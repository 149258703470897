import React from 'react';
import { BsQuestionCircleFill } from 'react-icons/bs';
import { UserQuizItem } from '../../types/userQuiz';
import { getApiPicture } from '../../utils';
import { getCurrentQuizzAction } from '../../actions/userQuizz';
import defaultPicture from '../../assets/images/plante.jpg';
import styles from './quizzList.module.scss';
import { useAppDispatch } from '../../reducers';

const QuizzLink = (
  {
    quiz,
  }
  :{
    quiz: UserQuizItem,
  },
) => {
  const picture = quiz?.picture?.path ? getApiPicture(quiz?.picture?.path) : defaultPicture;
  const dispatch = useAppDispatch();
  return (
    <button
      type="button"
      onClick={() => getCurrentQuizzAction(dispatch, quiz._id)}
      className={`${styles.link}`}>
      <div className={styles.picture}>
        {picture && <img src={picture} />}
      </div>
      <div className={styles.infos}>
        <p>{quiz.name}</p>
        <p>{0} Plantes</p>
      </div>
      <div className={styles.count}>
        <p>{quiz.countQuestions}</p>
        <BsQuestionCircleFill />
      </div>
    </button>
  );
};

export default function QuizzList({
  list = [],
  label,
}:{
  list?: UserQuizItem[];
  label?: string
}) {
  return (
    <div className={`${styles.container} ${label ? styles.home : ''}`}>
      {label && <label>{label}</label>}
      <div className={styles.list}>
        {list.map((q: UserQuizItem) => <QuizzLink
          key={q._id}
          quiz={q}
        />)}
      </div>
    </div>
  );
}
